import React, { useState, useEffect } from "react";
import "../../assets/scss/styles.scss"
import Img from 'react-cloudinary-lazy-image'
import Map from '../map.js'
import vid1 from '../../photos/vid1.mp4'
import vid2 from '../../photos/vid2.mp4'
import vid3 from '../../photos/vid3.mp4'
import vid4 from '../../photos/vid4.mp4'
import espa from "../../components/Headers/disclaimer.jpg"



import DarkFooter from "../../components/Footers/DarkFooter.js";
import {ImageGrid} from "react-fb-image-video-grid"

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";
import Gallery from "../../components/Gallery";

var settings = {
  columnCount:{
    default:3,
    mobile:3,
    tab:4
  },
  mode: 'dark'
}

function Packages() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  const [expanded, setExpanded] = React.useState(false);
   const [language, setLanguage] = useState(localStorage.getItem("i18nextLng") || "gr");
   
     const { t, i18n } = useTranslation();
      
        const changeLanguage = (lng) => {
          i18n.changeLanguage(lng); // Αλλαγή γλώσσας
          console.log(lng)
        };
  

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <section className="section section-about-us" id='publications'>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{color:'#0e3b5b',marginTop:'50px'}}> {t('packages')}
                </h2>
               <div style={{backgroundColor:'#e6e6e6',borderRadius:'10px',padding:'20px',textAlign:'left'}}>
                
               {[1, 2, 3, 4, 5, 6].map(wp => (
                <div key={wp}>
                    <h5><b style={{ color: '#0e3b5b' }}>{t(`wp${wp}`)}</b></h5>
                    <ul>
                        {[1, 2, 3, 4, 5, 6].map(task => (
                            t(`t${wp}${task}`) !== `t${wp}${task}` ? (
                                <li key={`t${wp}${task}`}>{t(`t${wp}${task}`)}</li>
                            ) : null
                        ))}
                    </ul>
                </div>
            ))}

               </div>
               <br/>
   
              </Col>
           
           

 
            </Row>
            </Container>
            </section>
       
               <section className="section section-about-us" id='poster' style={{backgroundColor:'#e4e4e4',padding:'20px'}}>
                                <Container>
                                <Row>
                                  <Col className="ml-auto mr-auto text-center" md="8" >
                                 
                      <a href='http://greece20.gov.gr' target='_blank' rel="noopener noreferrer">
                        <img 
                         
                          alt='espa-disclaimer' 
                          src={espa} 
                          style={{
                            cursor: 'pointer',
                            position: 'relative',
                          width:'400px'
                          }} 
                        />
                      </a>
                    
                                  
                                </Col>
                                  
                                </Row>
                                </Container>
                                </section>
        <DarkFooter />
        
      </div>
    </>
  );
}

export default Packages;
