import React, { useState, useEffect } from "react";
import "../../assets/scss/styles.scss"
import Img from 'react-cloudinary-lazy-image'
import Map from '../map.js'
import vid1 from '../../photos/vid1.mp4'
import vid2 from '../../photos/vid2.mp4'
import vid3 from '../../photos/vid3.mp4'
import vid4 from '../../photos/vid4.mp4'
import espa from "../../components/Headers/disclaimer.jpg"



import DarkFooter from "../../components/Footers/DarkFooter.js";
import {ImageGrid} from "react-fb-image-video-grid"

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";
import Gallery from "../../components/Gallery";

var settings = {
  columnCount:{
    default:3,
    mobile:3,
    tab:4
  },
  mode: 'dark'
}

function Publications() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  const [expanded, setExpanded] = React.useState(false);
   const [language, setLanguage] = useState(localStorage.getItem("i18nextLng") || "gr");
   
     const { t, i18n } = useTranslation();
      
        const changeLanguage = (lng) => {
          i18n.changeLanguage(lng); // Αλλαγή γλώσσας
          console.log(lng)
        };
  

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <section className="section section-about-us" id='publications'>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{color:'#0e3b5b',marginTop:'50px'}}> {t('nav3')}
                </h2>
               <div style={{backgroundColor:'#e6e6e6',borderRadius:'10px',padding:'20px',textAlign:'left'}}>
                <h5><b style={{color:'#0e3b5b'}}>Localization and Offline Mapping of High-Voltage Substations in Rough Terrain Using a Ground Vehicle</b></h5>
                <p><b>Authors:</b> I. Alamanos, G. P. Moustris and C. S. Tzafestas</p>
                <p>2024 32nd Mediterranean Conference on Control and Automation (MED), Chania - Crete, Greece, pp. 107-112,</p>
                <p><b>DOI:</b> <a style={{color:'#27a1d7'}}  href='https://ieeexplore.ieee.org/document/10566151'>10.1109/MED61351.2024.10566151</a></p>
               </div>
               <br/>
               <div style={{backgroundColor:'#e6e6e6',borderRadius:'10px',padding:'20px',textAlign:'left'}}>
                <h5><b style={{color:'#0e3b5b'}}>A framework for the robotic automation of ultra-high voltage center inspection tasks</b></h5>
                <p><b>Authors:</b> T. Flessa, G. Bonias, A. Skembris, D. I. Makrygiorgou, and D. Melissaris</p>
                <p>IET Conference Proceedings, vol. 2024, no. 29, pp. 219–224,</p>
                <p><b>DOI:</b><a style={{color:'#27a1d7'}} href='https://digital-library.theiet.org/doi/10.1049/icp.2024.4663'> 10.1049/icp.2024.4663</a></p>
         
               </div>
               <br/>
               <div style={{backgroundColor:'#e6e6e6',borderRadius:'10px',padding:'20px',textAlign:'left'}}>
                <h5><b style={{color:'#0e3b5b'}}>SDPL-SLAM: Introducing Lines in Dynamic Visual SLAM and Multi-Object Tracking</b></h5>
                <p><b>Authors:</b> A. Manetas, P. Mermigkas and P. Maragos</p>
                <p>2024 IEEE/RSJ International Conference on Intelligent Robots and Systems (IROS), Abu Dhabi, United Arab Emirates, 2024, pp. 7893-7899,</p>
                <p><b>DOI:</b><a style={{color:'#27a1d7'}} href='https://ieeexplore.ieee.org/document/10802140'> 10.1109/IROS58592.2024.10802140</a></p>
               </div>
               <br/>
               <div style={{backgroundColor:'#e6e6e6',borderRadius:'10px',padding:'20px',textAlign:'left'}}>
                <h5><b style={{color:'#0e3b5b'}}>A Visibility-Based Near-Optimal Planner for Robotic Inspection in Ultra High Voltage Centers</b></h5>
                <p><b>Authors:</b> A. Vatistas, G. P. Moustris and C. S. Tzafestas</p>
                <p>2025 23rd European Control Conference (ECC), Thessaloniki, Greece, 2025</p>
                <p>(Accepted)</p>
               </div>
              </Col>
           
           

 
            </Row>
            </Container>
            </section>
               <section className="section section-about-us" id='poster' style={{backgroundColor:'#e4e4e4',padding:'20px'}}>
                                <Container>
                                <Row>
                                  <Col className="ml-auto mr-auto text-center" md="8" >
                                 
                      <a href='http://greece20.gov.gr' target='_blank' rel="noopener noreferrer">
                        <img 
                         
                          alt='espa-disclaimer' 
                          src={espa} 
                          style={{
                            cursor: 'pointer',
                            position: 'relative',
                          width:'400px'
                          }} 
                        />
                      </a>
                    
                                  
                                </Col>
                                  
                                </Row>
                                </Container>
                                </section>
        <DarkFooter />
        
      </div>
    </>
  );
}

export default Publications;
