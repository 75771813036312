import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "title": "ENORASI",
      "subtitle": "Robotic Inspection of High Voltage Centers",
      "nav1" : "Home",
      "nav2" : "Gallery",
      "nav3" : "Publications",
      "nav4" : "Contact",
      "problem": "The Problem",
      "description1": "The smooth operation of High Voltage Centers (HVC) and Substations (SS) is vital for the uninterrupted transmission and distribution of the precious resource of electrical energy.",
      "description2": "Unpredictable hardware failures and/or failures due to the impact of rapid transient phenomena result in equipment malfunctions that lead to power outages in large areas of the country.",
      "description3": "It is a common observation among specialists in the maintenance of this equipment that such failures can generally be prevented if technical means are available for systematic monitoring of the equipment’s condition and early diagnosis of potential hardware failures.",
      "title2": "Research Project ENORASI",
      "description4": "The visualization of the temperature of electrical components is considered the most effective method for diagnosing and detecting faults. Despite its necessity, frequent and systematic monitoring of the temperature of critical equipment requires specialized personnel and a significant amount of time to cover all relevant installations. Therefore, condition-based maintenance requires the use of advanced new technology that enables the automated execution of measurements, data acquisition, and analysis.",
      "description5": "A solution can be provided by an autonomous mobile robotic vehicle capable of performing automated inspections using a visual and thermal camera system. The mobile robot will record the critical electrical components of a High Voltage Substation (HVS)  by performing reliable periodic measurements and will identify faults with the bases of optics and thermal photographs using artificial intelligence.",
      "description6": "The proposed system will enable frequent and fully automated inspections of the equipment's condition and will lead to the following economic, managerial, and informational benefits:",
      "ul1":"Reduction of HVS and Substation maintenance costs by more than 20% over time due to the transition from schedule-based maintenance to condition-based maintenance.",
      "ul2":"Frequent monitoring of equipment condition, which directly enhances the reliability of the electrical grid and, consequently, increases availability.",
      "ul3":"Reduction of the indirect effects of power outages on the economy and security.",
      "ul4":"The ability to manage and present large volumes of digital inspection data, leading to a better understanding of failure mechanisms and, therefore, further improvement in the operation of HVS and substations.",
      "partners":"Partners",
      "partner1":"ΕΠΙΣΕΥ",
      "partner1desc":"The Research University Institute of Communication and Computer Systems (ICCS) of the School of Electrical and Computer Engineering (ECE) at the National Technical University of Athens (NTUA) was founded in 1989.",
      "partner2":"IPTO",
      "partner2desc":"ΙPTO S.A. (Independent Power Transmission Operator S.A.) is the operator of the Hellenic Electricity Transmission System (HETS).",
      "partner3":"Castalia",
      "partner3desc":"We are a team of engineers and innovation consultants bringing together interdisciplinary skill sets in Artificial Intelligence and Human-Machine Interfaces, aiming to streamline Mind-to-Machine communication.",
      "partner4":"THL",
      "partner4desc":"THL (Tech Hive Labs) develops artificial intelligence and automation systems that can help humans and machines across various industries perform better, smarter, faster, and with greater safety.",
      "More":"Read More",
      "contact":"Contact Us",
      "name":"Name",
      "email":"Email",
      "message":"Message",
      "send":"Send",
      "images":"Images",
      "video":"Videos",
      "visit":"Visit to the Pallini High Voltage Substation (HVS)",
      "footer":"The project is implemented under the Action 'RESEARCH – CREATE – INNOVATE' 16971 of the National Recovery and Resilience Plan 'Greece 2.0', with funding from the European Union – NextGenerationEU (Implementing Body: EYDE EK, project code: TAEDK-06172).",
      "packages":"Work Packages",
                     "wp1":"WP1: Functional, Technical Specifications, and System Architecture Design",
                     "t11":   "T1.1 – Functional and technical specifications and system architecture",
                     "t12":   "T1.2 – User requirements",
                     "t13":   "T1.3 – Market research for selecting a robotic platform and measuring instruments",
                     "t14":   "T1.4 – Process of collecting existing inspection data from substations and transmission centers (ADMIE)",
                     "wp2":   "WP2: Central Control System, Robotic Vehicle Navigation, and Automatic Camera Targeting",
                     "t21":   "T2.1 – Research and development of an algorithm for automatic camera positioning for equipment inspection",
                     "t22":   "T2.2 – Research and development of data fusion algorithms for automatic robot localization",
                     "t23":   "T2.3 – Research and development of a central control system, path planning, and real-time obstacle avoidance",
                     "t24":   "T2.4 – Remote control system for the robotic vehicle",
                     "t25":   "T2.5 – Publications",
                     "wp3":   "WP3: Research and Development of Optical Inspection, Fault Detection, and Recognition System",
                     "t31":   "T3.1 – Mechatronic design and implementation of a non-destructive optical inspection system",
                     "t32":   "T3.2 – 3D mapping of the environment",
                     "t33":   "T3.3 – Research and development of optical recognition algorithms for electrical equipment with automatic localization",
                     "t34":   "T3.4 – Research and development of optical fault recognition and wear assessment algorithms",
                     "t35":   "T3.5 – Publications",
                     "wp4":   "WP4: Development of an Information System",
                     "t41":   "T4.1 – Scheduling of robotic inspection tasks",
                     "t42":   "T4.2 – Development of a database for storing and managing optical and thermal images of electrical equipment in transmission centers",
                     "t43":   "T4.3 – Development of an information system for data presentation and decision support",
                     "t44":   "T4.4 – Publications",
                     "wp5":   "WP5: System Integration and Construction of a Charging and Storage Station",
                     "t51":   "T5.1 – Design and development of a charging station",
                     "t52":   "T5.2 – Design and construction of a storage station",
                     "t53":   "T5.3 – Integration of the ENORASI system software",
                     "t54":   "T5.4 – Hardware integration and installation of the complete system at the Pallini transmission center",
                     "wp6":   "WP6: Pilot Implementation",
                     "t61":   "T6.1 – First pilot test",
                     "t62":   "T6.2 – Second pilot test",
                     "material":"Promo Material",
                     "poster":"Poster",
                     "download":"download .pdf",
                     "team" :"Project team",
                     "professor":"P.I. & Scientific Director:",
                     "tzaf":"Assoc. Prof. Costas Tzafestas (PI & Project Coordinator)",
                     "ep1":"Prof. Petros Maragos (co-PI)",
                     "ep2":"Dr. George Moustris (co-PI)",
                     "ep3":"Ioannis Alamanos",
                     "ep4":"Panagiotis Mermigkas",
                     "ep5":"Andreas Vatistas",
                     "ep6":"Nikos Kardaris",
                     "aggelos":'Angelos Skembris',
                     "george":"George Bonias",
                     "thaleia":"Thaleia Flessa",
                     "ioanna" : "Ioanna Leonti",
                     "tw1":"Aristidis Geladaris",
                    "tw2":"Athanasios Mastrogeorgiou",
                    "tw3":"Panagiotis Chatzakos",
                    "tw4":"Antonis Porichis",
                    "ipto1":"Despoina Makrygiorgou",
                    "ipto2":"Dimitris Melissaris",
               


    }
  },
  gr: {
    translation: {
      "title": "ΕΝΟΡΑΣΗ",
      "subtitle": "Ρομποτική Eπιθεώρηση Κέντρων Υπερυψηλής Τάσης",
      "nav1" : "Αρχική",
      "nav2" : "Εικόνες/Βίντεο",
      "nav3" : "Δημοσιεύσεις",
      "nav4" : "Επικοινωνία",
      "problem": "Το Πρόβλημα",
      "description1": "Η εύρυθμη λειτουργία των Κέντρων Υπερυψηλής Τάσης (ΚΥΤ) και των Υποσταθμών (ΥΣ) είναι ζωτικής σημασίας για την αδιάκοπη μεταφορά και διανομή του πολύτιμου αγαθού της ηλεκτρικής ενέργειας.",
      "description2": "Η μη προβλέψιμη αστοχία υλικού ή/και αστοχίες οφειλόμενες σε επίδραση ταχέων μεταβατικών φαινομένων έχει ως αποτέλεσμα την αστοχία εξοπλισμού που οδηγεί σε διακοπή ρεύματος σε μεγάλα τμήματα της χώρας.",
      "description3": "Αποτελεί κοινή διαπίστωση των ειδικών για τη συντήρηση του εν λόγω εξοπλισμού ότι τέτοιου τύπου βλάβες μπορεί εν γένει να αποφευχθούν εάν ήταν διαθέσιμα τεχνικά μέσα για τη συστηματική παρατήρηση της κατάστασης του εξοπλισμού και την έγκαιρη διάγνωση ενδεχόμενων αστοχιών υλικού.",
      "title2": "Ερευνητικό Έργο ΕΝΟΡΑΣΗ",
      "description4": "Η απεικόνιση της θερμοκρασίας των ηλεκτρολογικών στοιχείων θεωρείται ο αποτελεσματικότερος τρόπος για τη διάγνωση και τον εντοπισμό βλαβών. Παρ' όλη την αναγκαιότητα, η συχνή και συστηματική παρακολούθηση της θερμοκρασίας του καίριου εξοπλισμού απαιτεί εξειδικευμένο προσωπικό και πολύ χρόνο για να καλυφθούν όλες οι σχετικές εγκαταστάσεις. Συνεπώς, για την συντήρηση βάσει παρατήρησης της κατάστασης απαιτείται η χρήση νέας προηγμένης τεχνολογίας που επιτυγχάνει αυτοματοποιημένη εκτέλεση μετρήσεων, λήψης και ανάλυσης δεδομένων.",
      "description5": "Τη λύση μπορεί να προσφέρει ένα αυτόνομο κινούμενο ρομποτικό όχημα το οποίο έχει τη δυνατότητα για αυτοματοποιημένη επιθεώρηση χρησιμοποιώντας σύστημα οπτικής και θερμικής κάμερας. Το κινούμενο ρομπότ θα καταγράφει τα καίρια ηλεκτρολογικά στοιχεία ενός ΚΥΤ διενεργώντας αξιόπιστες περιοδικές μετρήσεις και θα αναγνωρίζει βλάβες με βάση τις οπτικές και θερμικές φωτογραφίες με χρήση τεχνητής νοημοσύνης.",
      "description6": "Το προτεινόμενο σύστημα θα επιτρέψει τη διεξαγωγή συχνών και πλήρως αυτοματοποιημένων επιθεωρήσεων της κατάστασης του εξοπλισμού και θα οδηγήσει στα ακόλουθα οικονομικό, διαχειριστικά και πληροφοριακό οφέλη:",
      "ul1":"Μείωση κόστους συντήρησης ΚΥΤ και ΥΣ σε βάθος χρόνου μεγαλύτερη από 20%, λόγω της μετάβασης από συντήρηση βάσει χρονοδιαγράμματος σε συντήρηση βάσει κατάστασης του εξοπλισμού.",
      "ul2":"Συχνή παρακολούθηση της κατάστασης του εξοπλισμού που οδηγεί άμεσα σε μεγαλύτερη αξιοπιστία του ηλεκτρικού δικτύου και κατά συνέπεια μεγαλύτερη διαθεσιμότητα.",
      "ul3":"Μείωση των έμμεσων επιπτώσεων των διακοπών ρεύματος στην οικονομία και την ασφάλεια.",
      "ul4":"Δυνατότητα διαχείρισης και παρουσίασης μεγάλου όγκου ψηφιακών δεδομένων επιθεωρήσεων τα οποία οδηγούν στην καλύτερη κατανόηση των μηχανισμών βλάβης και συνεπώς στην περαιτέρω βελτίωση της λειτουργίας των ΚΥΤ και των ΥΣ.",
      "partners":"Εταίροι",
      "partner1":"ΕΠΙΣΕΥ",
      "partner1desc":"Το Ερευνητικό Πανεπιστημιακό Ινστιτούτο Συστημάτων Επικοινωνιών και Υπολογιστών (ΕΠΙΣΕΥ) της Σχολής Ηλεκτρολόγων Μηχανικών και Μηχανικών Υπολογιστών (ΣΗΜΜΥ) του Εθνικού Μετσόβιου Πολυτεχνείου (ΕΜΠ) ιδρύθηκε το 1989.",
      "partner2":"ΑΔΜΗΕ",
      "partner2desc":"Ο ΑΔΜΗΕ (Ανεξάρτητος Διαχειριστής Μεταφοράς Ηλεκτρικής Ενέργειας Α.Ε.) αποτελεί τον Διαχειριστή του Ελληνικού Συστήματος Μεταφοράς Ηλεκτρικής Ενέργειας (ΕΣΜΗΕ).",
      "partner3":"Castalia",
      "partner3desc":"Είμαστε μια ομάδα μηχανικών και συμβούλων καινοτομίας που συγκεντρώνει διεπιστημονικά σύνολα δεξιοτήτων στην Τεχνητή Νοημοσύνη και τις Διασυνδέσεις Ανθρώπου-Μηχανής με στόχο τον εξορθολογισμό της επικοινωνίας Mind-to-Machine.",
      "partner4":"THL",
      "partner4desc":"Η THL (Tech Hive Labs) αναπτύσσει συστηματα τεχνητής νοημοσύνης και αυτοματοποίησης, που μποροούν να βοηθήσουν ανθρώπους και μηχανές σε διαφορετικούς κλάδους να αποδίδουν καλύτερα, πιο έξυπνα, πιο γρήγορα και με μεγαλύτερη ασφάλεια.",
      "More":"Περισσότερα",
      "contact":"Επικοινωνήστε μαζί μας",
      "name":"Όνομα",
      "email":"Email",
      "message":"Μήνυμα",
      "send":"Αποστολή",
      "images":"Εικόνες",
      "video":"Βίντεο",
      "visit":"Επίσκεψη στο KYT Παλλήνης",
      "footer":"Το έργο υλοποιείται στο πλαίσιο της Δράσης ΕΡΕΥΝΩ – ΔΗΜΙΟΥΡΓΩ – ΚΑΙΝΟΤΟΜΩ 16971 του Εθνικού Σχεδίου Ανάκαμψης και Ανθεκτικότητας «Ελλάδα 2.0», με τη χρηματοδότηση της Ευρωπαϊκής Ένωσης – NextGenerationEU (Φορέας Υλοποίησης: ΕΥΔΕ ΕΚ, κωδικός έργου: ΤΑΕΔΚ-06172)",
      "packages":"Ενότητες Εργασίας",
      "wp1": "WP1: Λειτουργικές, Τεχνικές Προδιαγραφές και Σχεδιασμός Αρχιτεκτονικής Συστήματος",
    "t11": "T1.1 – Λειτουργικές και τεχνικές προδιαγραφές και αρχιτεκτονική συστήματος",
    "t12": "T1.2 – Απαιτήσεις Χρήστη",
    "t13": "T1.3 – Έρευνα αγοράς για την επιλογή ρομποτικής πλατφόρμας και μετρητικών οργάνων",
    "t14": "T1.4 – Διαδικασία συλλογής υπαρχόντων δεδομένων επιθεώρησης από Υ/Σ και ΚΥΤ ΑΔΜΗΕ",
    "wp2": "WP2: Κεντρικό Σύστημα Ελέγχου, Πλοήγησης Ρομποτικού Οχήματος και Αυτόματη Σκόπευση Κάμερας",
    "t21": "T2.1 – Έρευνα και ανάπτυξη αλγορίθμου αυτόματου υπολογισμού θέσης καμερών για την επιθεώρηση του εξοπλισμού",
    "t22": "T2.2 – Έρευνα και ανάπτυξη αλγορίθμων σύμμειξης δεδομένων για την αυτόματη χωροθέτηση του ρομπότ",
    "t23": "T2.3 – Έρευνα και ανάπτυξη κεντρικού συστήματος ελέγχου, σχεδιασμού πορείας και αυτόματης αποφυγής εμποδίων σε πραγματικό χρόνο",
    "t24": "T2.4 – Σύστημα τηλεχειρισμού ρομποτικού οχήματος",
    "t25": "T2.5 – Δημοσιεύσεις",
    "wp3": "WP3: Έρευνα και Ανάπτυξη Οπτικού Συστήματος Επιθεώρησης, Εντοπισμού και Αναγνώρισης Βλαβών",
    "t31": "T3.1 – Μηχατρονική σχεδίαση και υλοποίηση οπτικού συστήματος μη καταστροφικής επιθεώρησης",
    "t32": "T3.2 – Τριδιάστατη χαρτογράφηση του χώρου",
    "t33": "T3.3 – Έρευνα και ανάπτυξη αλγορίθμων οπτικής αναγνώρισης ηλεκτρολογικού εξοπλισμού με αυτόματη χωροθέτηση",
    "t34": "T3.4 – Έρευνα και ανάπτυξη αλγορίθμων οπτικής αναγνώρισης βλαβών και αποτίμησης φθοράς",
    "t35": "T3.5 – Δημοσιεύσεις",
    "wp4": "WP4: Ανάπτυξη Πληροφοριακού Συστήματος",
    "t41": "T4.1 – Προγραμματισμός καθηκόντων ρομποτικής επιθεώρησης",
    "t42": "T4.2 – Ανάπτυξη βάσης δεδομένων για την αποθήκευση και διαχείριση δεδομένων οπτικών και θερμικών εικόνων ηλεκτρολογικού εξοπλισμού ΚΥΤ",
    "t43": "T4.3 – Ανάπτυξη πληροφοριακού συστήματος παρουσίασης δεδομένων και συστήματος υποστήριξης λήψης αποφάσεων",
    "t44": "T4.4 – Δημοσιεύσεις",
    "wp5": "WP5: Ενοποίηση συστήματος και κατασκευή σταθμού φύλαξης και τροφοδοσίας",
    "t51": "T5.1 – Σχεδιασμός και ανάπτυξη σταθμού τροφοδοσίας",
    "t52": "T5.2 – Σχεδιασμός και κατασκευή σταθμού φύλαξης",
    "t53": "T5.3 – Ενοποίηση λογισμικού συστήματος ΕΝΟΡΑΣΗ",
    "t54": "T5.4 – Ενοποίηση υλικού και εγκατάσταση ολοκληρωμένου συστήματος στο ΚΥΤ Παλλήνης",
    "wp6": "WP6: Πιλοτική Εφαρμογή",
    "t61": "T6.1 – Α' Πιλοτική Δοκιμή",
    "t62": "T6.2 – Β' Πιλοτική Δοκιμή",
    "material":"Προωθητικό Υλικό",
    "poster":"Αφίσα",
    "download":"Αποθήκευση αρχέιου .pdf",
    "team" :"Ομάδα",
    "professor":"P.I. & Scientific Director:",
    "tzaf":"Αν. Καθ. Κωσταντίνος Τζαφέστας (Επιστημονικός Υπεύθυνος)",
    "ep1":"Καθ. Πέτρος Μαραγκός",
    "ep2":"Δρ. Γεώργιος Μούστρης",
    "ep3":"Ιωάννης Αλαμάνος",
    "ep4":"Παναγιώτης Μέρμηγκας",
    "ep5":"Ανδρέας Βατίστας",
    "ep6":"Νικόλαος Κάρδαρης",
    "aggelos":'Άγγελος Σκέμπρης',
    "george":"Γιώργος Μπόνιας",
    "thaleia":"Θάλεια Φλέσσα",
    "ioanna":'Ιωάννα Λεόντη',
    "tw1":"Αριστείδης Γελαδάρης",
    "tw2":"Αθανάσιος Μαστρογεωργίου",
    "tw3":"Παναγιώτης Χατζάκος",
    "tw4":"Αντώνης Πορίχης",
    "ipto1":"Δέσποινα Μακρυγιώργου",
    "ipto2":"Δημήτρης Μελισσάρης",


    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources, // Χρησιμοποιούμε τις μεταφράσεις απευθείας
    lng: 'gr', // Προεπιλεγμένη γλώσσα
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
