import React from 'react'
import gold from './goldlogo.png'
import { Slide } from 'react-slideshow-image';
import Fade from 'react-reveal/Fade';
import vid8 from '../../photos/vid8.mp4'
import { useTranslation } from 'react-i18next';
import '../../i18n'; // Εισαγωγή της ρύθμισης i18n


import Slideshow from './slideshow.js'

function Head() {
   const { t, i18n } = useTranslation();
  
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng); // Αλλαγή γλώσσας
      console.log(lng)
    };
  return (
    <header id='head'>
      
    <div class="containernew">
    
    <section class="showcase" id='landing' >
      <div style={{marginTop:'150px',padding:'5%'}}>
        <Fade duration={2000}>
        <h2 id='up' >{t('title')}</h2>
        </Fade>
        <Fade duration={3000}>
        <h2 id='up' style={{fontSize:'20px'}}>
        {t('subtitle')}
        </h2>
        </Fade>
        <Fade duration={5000}>
        </Fade>
        
        </div>
        
    </section>
    
</div>

<div class="video-container">
<video loop autoPlay muted >
      <source src={vid8} type="video/mp4"/>
     </video>
</div>
</header>


)}
export default Head;