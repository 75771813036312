import React, { useState, useEffect } from "react";

import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Link } from "react-router-dom";
import Scroll from 'react-scroll'
import logo from './enorasilogo.png'
import { HashLink } from "react-router-hash-link";
import img1 from '../../photos/greece.png'
import img2 from '../../photos/united-kingdom.png'
// reactstrap components
import {
  Collapse,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Button,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import FontSizeChanger from 'react-font-size-changer';
import { useTranslation } from "react-i18next";
import i18next from "i18next";



const ScrollLink = Scroll.ScrollLink
function ExamplesNavbar() {
  const [navbarColor, setNavbarColor] = useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("i18nextLng") || "gr");
 
   const { t, i18n } = useTranslation();
    
      const changeLanguage = (lng) => {
        i18n.changeLanguage(lng); // Αλλαγή γλώσσας
        console.log(lng)
      };



      useEffect(() => {
        if (!localStorage.getItem("i18nextLng")) {
          localStorage.setItem("i18nextLng", "gr");
        }
        i18n.changeLanguage(language);
      }, [language, i18n]);

  useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  }, []);

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
    localStorage.setItem("i18nextLng", e.target.value);
  };

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} color="info" expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand>
              <HashLink smooth offset={() => 100} style={{ fontSize: '12px', textDecoration: 'none' }} to='/'>
                <img src={logo}alt="enorasi-logo" style={{ width: '160px', padding: '0px 5px' }} />
              </HashLink>
            </NavbarBrand>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-label='navbar'
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink style={{ fontSize: '12px' }}>
                  <HashLink aria-label='Aρχική' id='up' smooth offset={() => 100} style={{ fontSize: '14px', textDecoration: 'none' }} to='/#landing'> {t('nav1')}</HashLink>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink style={{ fontSize: '12px' }}>
                  <HashLink aria-label='Gallery' id='up' smooth offset={() => 100} style={{ fontSize: '14px', textDecoration: 'none' }} to='/gallery'>{t('nav2')}</HashLink>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink style={{ fontSize: '12px' }}>
                  <HashLink aria-label='publications' id='up' smooth offset={() => 100} style={{ fontSize: '14px', textDecoration: 'none' }} to='/publications'>{t('nav3')}</HashLink>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink style={{ fontSize: '12px' }}>
                  <HashLink aria-label='packages' id='up' smooth offset={() => 100} style={{ fontSize: '14px', textDecoration: 'none' }} to='/packages'>{t('packages')}</HashLink>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink style={{ fontSize: '12px' }}>
                  <HashLink aria-label='packages' id='up' smooth offset={() => 100} style={{ fontSize: '14px', textDecoration: 'none' }} to='/team'>{t('team')}</HashLink>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink style={{ fontSize: '12px' }}>
                  <HashLink aria-label='Eπικοινωνία' id='up' smooth offset={() => 100} style={{ fontSize: '14px', textDecoration: 'none' }} to='/#contact'>{t('nav4')}</HashLink>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink style={{ fontSize: '12px' }}>
                  <FontSizeChanger
                    targets={['#up']}
                    onChange={(element, newValue, oldValue) => { console.log(element, newValue, oldValue); }}
                    options={{
                      stepSize: 2,
                      range: 3
                    }}
                    customButtons={{
                      up: <span style={{ 'fontSize': '24px', top: '-6px', position: 'relative' }}>A</span>,
                      down: <span style={{ 'fontSize': '16px', top: '-6px', position: 'relative' }}>A</span>,
                      style: {
                        backgroundColor: '#165473',
                        color: '#dadada',
                        border: "1px solid #dadada",
                        WebkitBoxSizing: 'border-box',
                        WebkitBorderRadius: '10px',
                        width: '30px',
                        height: '30px'
                      },
                      buttonsMargin: 10
                    }}
                  />
                </NavLink>
              </NavItem>
                 {/* Language Dropdown */}
                 <NavItem>
                <NavLink style={{ fontSize: '12px' }}>
                  <div className="custom-dropdown">
                    <button className="dropdown-btn">
                      {language === 'gr' ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          
                          <img src={img1} alt="Greek" style={{ width: '15px', marginRight: '6px' }} />
                          Ελληνικά
                        </div>
                      ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={img2} alt="English" style={{ width: '15px', marginRight: '6px' }} />
                          English
                        </div>
                      )}
                    </button>
                    <div className="dropdown-content">
                      <button onClick={() => handleLanguageChange({ target: { value: 'gr' } })}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={img1} alt="Greek" style={{ width: '15px', marginRight: '6px' }} />
                          Ελληνικά
                        </div>
                      </button>
                      <button onClick={() => handleLanguageChange({ target: { value: 'en' } })}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={img2} alt="English" style={{ width: '15px', marginRight: '6px' }} />
                          English
                        </div>
                      </button>
                    </div>
                  </div>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ExamplesNavbar;