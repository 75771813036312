import React, { Component } from 'react'
import img1 from '../photos/img1.png'
import img2 from '../photos/img2.jpg'
import img3 from '../photos/img3.jpg'
import img4 from '../photos/img4.jpg'
import img5 from '../photos/img5.jpg'
import img6 from '../photos/img6.jpg'
import img7 from '../photos/IMG_1.jpg'
import img8 from '../photos/IMG_2.jpg'
import img9 from '../photos/IMG_3.jpg'
import img10 from '../photos/IMG_4.jpg'
import ReactImageVideoLightbox from 'react-image-video-lightbox'
import Lightroom from 'react-lightbox-gallery'

export default class Gallery extends Component {
  render() {
    var images = [
      {
        src: img1,
        desc: 'enorasi',
      },
      {
        src: img2,
      
    },
    {
      src: img3,
     
    },
    {
      src: img4,
     
    },
    {
      src: img6,
     
    },
    {
      src: img5,
     
    }
    , {
      src: img10,
     
    },
    {
      src: img7,
     
    },
    {
      src: img8,
     
    },
    {
      src: img9,
     
    }
   
    ]
    var settings = {
      columnCount:{
        default:5,
        mobile:3,
        tab:4
      },
      mode: 'dark'
    }
    return (
      <div>
        <Lightroom images={images} settings={settings} />
        <br/><br/><br/>
       
      </div>
    )
  }
}

