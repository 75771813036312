import React, { useState, useEffect } from "react";
import "../../assets/scss/styles.scss"
import Img from 'react-cloudinary-lazy-image'
import Map from '../map.js'
import vid1 from '../../photos/vid1.mp4'
import vid2 from '../../photos/vid2.mp4'
import vid3 from '../../photos/vid3.mp4'
import vid4 from '../../photos/vid4.mp4'
import espa from "../../components/Headers/disclaimer.jpg"
import episei from "../../assets/img/logos/episei2.png"
import castalia from "../../assets/img/logos/castalia2.png"
import twi from "../../assets/img/logos/THL-logo.png"
import ipto from "../../assets/img/logos/IPTO English Logo (1).png"


import DarkFooter from "../../components/Footers/DarkFooter.js";
import {ImageGrid} from "react-fb-image-video-grid"

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";
import Gallery from "../../components/Gallery";

var settings = {
  columnCount:{
    default:3,
    mobile:3,
    tab:4
  },
  mode: 'dark'
}

function Team() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  const [expanded, setExpanded] = React.useState(false);
   const [language, setLanguage] = useState(localStorage.getItem("i18nextLng") || "gr");
   
     const { t, i18n } = useTranslation();
      
        const changeLanguage = (lng) => {
          i18n.changeLanguage(lng); // Αλλαγή γλώσσας
          console.log(lng)
        };
  

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <section className="section section-about-us" id='publications'>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="7">
                <h2 className="title" style={{color:'#0e3b5b',marginTop:'50px'}}> {t('team')}
                </h2>
               <div style={{backgroundColor:'#e6e6e6',borderRadius:'10px',padding:'20px'}}>
               <div style={{ display: 'flex', alignItems: 'center',  textAlign: 'left',backgroundColor:'#e6e6e6',borderRadius:'10px',padding:'20px'}}>
  <img src={episei} style={{ width: '150px', marginRight: '50px' }} />
  <div style={{}}>
   <p style={{marginBottom:'4px'}}> {t('tzaf')}</p>
   <p style={{marginBottom:'4px'}}> {t('ep1')}</p>
   <p style={{marginBottom:'4px'}}> {t('ep2')}</p>
   <p style={{marginBottom:'4px'}}> {t('ep3')}</p>
   <p style={{marginBottom:'4px'}}> {t('ep4')}</p>
   <p style={{marginBottom:'4px'}}> {t('ep5')}</p>
   <p style={{marginBottom:'4px'}}> {t('ep6')}</p>
   <a  style={{fontSize:'1.2em',color:'#27a1d7'}}href='https://robotics.ntua.gr/'>Link</a>
  </div>
</div>
               </div>
               <br/>
               <div style={{backgroundColor:'#e6e6e6',borderRadius:'10px',padding:'20px'}}>
               <div style={{ display: 'flex', alignItems: 'center',  textAlign: 'left',backgroundColor:'#e6e6e6',borderRadius:'10px',padding:'20px'}}>
  <img src={castalia} style={{ width: '150px', marginRight: '50px' }} />
  <div>
   <p style={{marginBottom:'4px'}}> {t('aggelos')}</p>
   <p style={{marginBottom:'4px'}}> {t('george')}</p>
   <p style={{marginBottom:'4px'}}> {t('thaleia')}</p>
   <p style={{marginBottom:'4px'}}> {t('ioanna')}</p>


  </div>
</div>
               </div>
               <br/>



            <div style={{backgroundColor:'#e6e6e6',borderRadius:'10px',padding:'20px'}}>
               <div style={{ display: 'flex', alignItems: 'center',  textAlign: 'left',backgroundColor:'#e6e6e6',borderRadius:'10px',padding:'20px'}}>
  <img src={twi} style={{ width: '150px', marginRight: '50px' }} />
  <div>
  <p style={{marginBottom:'4px'}}> {t('tw3')}</p>
   <p style={{marginBottom:'4px'}}> {t('tw4')}</p>
   <p style={{marginBottom:'4px'}}> {t('tw1')}</p>
   <p style={{marginBottom:'4px'}}> {t('tw2')}</p>


  </div>
</div>
               </div>



               <br/>
              <div style={{backgroundColor:'#e6e6e6',borderRadius:'10px',padding:'20px'}}>
               <div style={{ display: 'flex', alignItems: 'center',  textAlign: 'left',backgroundColor:'#e6e6e6',borderRadius:'10px',padding:'20px'}}>
  <img src={ipto} style={{ width: '150px', marginRight: '50px' }} />
  <div>
  <p style={{marginBottom:'4px'}}> {t('ipto1')}</p>
  <p style={{marginBottom:'4px'}}> {t('ipto2')}</p>

  </div>
</div>
               </div>
              </Col>
           
           

 
            </Row>
            </Container>
            </section>
               <section className="section section-about-us" id='poster' style={{backgroundColor:'#e4e4e4',padding:'20px'}}>
                                <Container>
                                <Row>
                                  <Col className="ml-auto mr-auto text-center" md="8" >
                                 
                      <a href='http://greece20.gov.gr' target='_blank' rel="noopener noreferrer">
                        <img 
                         
                          alt='espa-disclaimer' 
                          src={espa} 
                          style={{
                            cursor: 'pointer',
                            position: 'relative',
                          width:'400px'
                          }} 
                        />
                      </a>
                    
                                  
                                </Col>
                                  
                                </Row>
                                </Container>
                                </section>
        <DarkFooter />
        
      </div>
    </>
  );
}

export default Team;
