import React from "react";
import "../../assets/scss/styles.scss"
import Img from 'react-cloudinary-lazy-image'
import Mapchart from '../map.js'
import signature from "../signaturejs.js";
import CookieConsent, { Cookies } from "react-cookie-consent";
import ContactForm from './contact'
import DarkFooter from "../../components/Footers/DarkFooter.js";
import cons from '../cons.png'
import test from '../../assets/img/test2.svg'
import { HashLink } from "react-router-hash-link";
import CustomForm from '../../components/SubscribeForm'
import episei from "../../assets/img/logos/episei2.png"
import castalia from "../../assets/img/logos/castalia.png"
import twi from "../../assets/img/logos/THL-logo2.png"
import ipto from "../../assets/img/logos/ipto.png"
import FontSizeChanger from 'react-font-size-changer';
import { useTranslation } from 'react-i18next';
import poster from "../../photos/poster.png"
import '../../i18n'; // Εισαγωγή της ρύθμισης i18n
import pdf from './enorasi-poster.pdf'
import icon from './pdf.svg'
import espa from "../../components/Headers/disclaimer.jpg"



// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";
import MailchimpSubscribe from "react-mailchimp-subscribe"


const url = "https://gmail.us14.list-manage.com/subscribe/post?u=047540e6f745f9512f7cbb36e&amp;id=a0467af439&amp;f_id=00faf4e0f0";

const SimpleForm = () => <MailchimpSubscribe url={url}/>

function LandingPage() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Αλλαγή γλώσσας
    console.log(lng)
  };
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        
        <LandingPageHeader/>
       
        <section className="section section-about-us" id='mission'>

          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" id='up' style={{color:'#0e3b5b'}}>{t('problem')}</h2>
                <p id='up' className="description" style={{textAlign:'justify',color:'#07353d'}}>
                <p id='up' style={{fontSize:'15px',textAlign:'justify',color:'#07353d'}}>
                {t('description1')}</p>
<p style={{fontSize:'15px'}}>
{t('description2')}
</p>
<p style={{fontSize:'15px'}}>
{t('description3')}</p>
               
                </p>
              </Col>
            </Row>
            </Container>
            </section>
            <section className="section section-about-us" id='consortium' >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8" >
                <h2 id='up' className="title" style={{color:'white'}}>{t('title2')}</h2>
                <p className="description" style={{textAlign:'justify',color:'white'}}/>
                <p id='up' style={{fontSize:'15px',color:'whitesmoke',textAlign:'justify'}}>
               {t('description4')}
</p>
<p id='up' style={{fontSize:'15px',color:'whitesmoke',textAlign:'justify'}}>
{t('description5')}</p>
<p id='up' style={{fontSize:'15px',color:'whitesmoke',textAlign:'justify'}}>
{t('description6')}
</p>
<br/>
<ul id='up' style={{color:'whitesmoke',textAlign:'justify'}}>
  <li>{t('ul1')}</li>
  <li>{t('ul2')}</li>

  <li>{t('ul3')}</li>

  <li>{t('ul4')}</li>
</ul> 
                
               
              
              </Col>
              
            </Row>
            </Container>
            </section>
         
            <section className="section section-about-us" id='partners' >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8" >
                <h2 className="title" style={{color:'#0e3b5b'}}>{t('partners')}</h2>
                <p className="description" style={{textAlign:'justify',color:'white'}}/>
                <div class="card-wrap">
  <div class="card-header four">
    <img alt='episei-logo' src={episei}></img>
  </div>
  <div class="card-content">
    <h1 class="card-title" id='up'>{t('partner1')}</h1>
    <p id='up' class="card-text">{t('partner1desc')}</p>
    <a href='https://www.iccs.gr/el/' style={{color:'#269fd4'}}>{t('More')}</a>
    <br/>
 </div>
</div>
<div class="card-wrap">
  <div class="card-header three">
    <img alt='ipto-logo' src={ipto}></img>
  </div>
  <div class="card-content">
    <h1 id='up' class="card-title">{t('partner2')}</h1>
    <p id='up' class="card-text">{t('partner2desc')} </p>
    <a href='https://www.admie.gr/' style={{color:'#269fd4'}}>{t('More')}</a>
 </div>
</div>
                <div class="card-wrap">
  <div class="card-header one">
    <img alt='castalia-logo' src={castalia}></img>
  </div>
  <div class="card-content">
    <h1 id='up' class="card-title">{t('partner3')}</h1>
    <p id='up' class="card-text">{t('partner3desc')}</p>
    <a href='https://castalia.io/' style={{color:'#269fd4'}}>{t('More')}</a>
     <br/>
 </div>
</div>
<div class="card-wrap">
  <div class="card-header two">
    <img alt='twi-logo' src={twi}></img>
  </div>
  <div class="card-content">
    <h1 id='up'class="card-title">{t('partner4')}</h1>
    <p id='up'class="card-text">{t('partner4desc')}</p>
    <a href='https://www.linkedin.com/company/tech-hive-labs/posts/?feedView=all' style={{color:'#269fd4'}}>{t('More')}</a>
    <br/>
 </div>
</div>


              
            </Col>
              
            </Row>
            </Container>
            </section>

       
        <ContactForm/>
      
        <DarkFooter />
        <CookieConsent
  location="bottom"
  buttonText="Agree"
  aria-label="Agree"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#ffffff",padding:'10px',position:'fixed', overflowY:'auto',borderTop:'2px solid #1c9caa',display:'block'}}
  buttonStyle={{ color:"#ffffff", fontSize: "13px",borderRadius:'5px',background:'#0a2e4b'}}
  expires={150}
>
  <h2 style={{color:'#07353d',textTransform:'none',fontSize:'1em',fontWeight:'700',marginBottom:'0px'}}>In order to provide you with the best online experience this website uses cookies. By using our website, you agree to our use of cookies.</h2>
  

    
</CookieConsent>
      </div>
    </>
  );
}

export default LandingPage;
