import React,{Component} from 'react'
import CheckBox  from './toggle';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import test from '../../assets/img/test2.svg'
import logo2 from '../../components/Navbars/enorasi2.png'
import { useState } from 'react'
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import espa from "../../components/Headers/disclaimer.jpg"
import poster from "../../photos/poster.png"
import pdf from './enorasi-poster.pdf'
import icon from './pdf.svg'

import {
    Button,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
  } from "reactstrap";
  const initialState = {
    name: '',
    email: '',
    message: '',
  }
  export const ContactForm =()=>{
    const [{ name, email, message }, setState] = useState(initialState)
     const { t, i18n } = useTranslation();
    
      const changeLanguage = (lng) => {
        i18n.changeLanguage(lng); // Αλλαγή γλώσσας
        console.log(lng)
      };

    const handleChange = (e) => {
      const { name, value } = e.target
      setState((prevState) => ({ ...prevState, [name]: value }))
    }
    const clearState = () => setState({ ...initialState })
  
    const handleSubmit = (e) => {
      e.preventDefault()
      console.log(name, email, message)
      emailjs
        .sendForm(
          'service_ai424q9', 'template_dnd5yxs', e.target, 'j-QOqt3hQj_BJA3AD'
        )
        .then(
          (result) => {
            console.log(result.text)
            clearState()
          },
          (error) => {
            console.log(error.text)
          }
        )
    }

  return (
    <>
    <section id='contact' className="section section-contact-us text-center" style={{background:'linear-gradient(0deg, #012335 0, #1b7198)'}}>
    
      <div class="container">   
      <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap', justifyContent: 'center' }}>
      <div style={{ minWidth: '200px', backgroundColor:'whitesmoke',borderRadius:'10px',padding:'10px 20px 10px 20px' }}>
        <h4 className="title" style={{ color: '#0e3b5b',textAlign:'left' ,paddingTop:'0px',marginTop:'0px'}}>{t('material')}</h4>
      
          <img src={poster} style={{ width: '190px' }} alt="Poster" />
          <br/>
          <div style={{ textAlign: 'left' }}>
   <br/>
            <a style={{ color: '#1f82b0', textDecoration: 'none' }} id='linkid' href={pdf} download="Enorasi-Poster.pdf">
              <img src={icon} style={{ width: '20px', marginRight: '6px' }} alt="Download Icon" />{t('download')}
            </a>
          
        </div>
      </div>
      
      <div className="screen" style={{ flex: '1 1 500px', minWidth: '300px',maxWidth:'550px' }}>
        <div className="screen-header">
          <div className="screen-header-left">
            <div className="screen-header-button close"></div>
            <div className="screen-header-button maximize"></div>
            <div className="screen-header-button minimize"></div>
          </div>
          <div className="screen-header-right">
            <div className="screen-header-ellipsis"></div>
            <div className="screen-header-ellipsis"></div>
            <div className="screen-header-ellipsis"></div>
          </div>
        </div>
        <div className="screen-body">
          <div className="screen-body-item left">
            <div className="app-title">
              <span>{t('contact')} </span>
            </div>
            <br /><br />
            <img alt='enorasi-logo' src={logo2} style={{ width: '150px' }} />
            <br />
            <ul style={{ listStyleType: 'none', paddingLeft: '10px' }}>
              <li id='social'><a target="_blank" aria-label="Linkedin" href='https://www.linkedin.com/'> <i id='social' className="fab fa-linkedin"></i></a></li>
              <li id='social'><a target="_blank" aria-label="Youtube" href='https://www.youtube.com/'> <i id='social' className="fab fa-youtube"></i></a></li>
              <li id='social'><a target="_blank" aria-label="Twitter" href='https://www.twitter.com/'> <i id='social' className="fab fa-twitter"></i></a></li>
            </ul>
          </div>
          <form name='sentMessage' validate onSubmit={handleSubmit}>
            <div className="screen-body-item">
              <div className="app-form">
                <div className="app-form-group">
                  <input className="app-form-control" placeholder={t('name')} type='text' id='name' value={name} aria-label="Όνομα" name='name' required onChange={handleChange} />
                </div>
                <div className="app-form-group">
                  <input className="app-form-control" placeholder={t('email')} type='email' id='email' aria-label="Email" value={email} name='email' required onChange={handleChange} />
                </div>
                <div className="app-form-group message">
                  <input className="app-form-control" placeholder={t('message')} name='message' id='message' aria-label="ΜΗΝΥΜΑ" rows='4' value={message} required onChange={handleChange} />
                </div>
                <div className="app-form-group buttons">
                  <button aria-label="send" className="app-form-button">{t('send')}</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
   
  </div>


        </section>
                    <section className="section section-about-us" id='poster' style={{backgroundColor:'#e4e4e4',padding:'20px'}}>
                    <Container>
                    <Row>
                      <Col className="ml-auto mr-auto text-center" md="8" >
                     
          <a href='http://greece20.gov.gr' target='_blank' rel="noopener noreferrer">
            <img 
             
              alt='espa-disclaimer' 
              src={espa} 
              style={{
                cursor: 'pointer',
                position: 'relative',
              width:'400px'
              }} 
            />
          </a>
        
                      
                    </Col>
                      
                    </Row>
                    </Container>
                    </section>
</>

);
 }
export default ContactForm