/*

=========================================================
* Now UI Kit React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-react
* Copyright 2021 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/main/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import favicon from './components/Navbars/enorasifavicon.svg'

// styles for this kit
import "./assets/css/bootstrap.min.css";
import "./assets/scss/now-ui-kit.scss";
import "./assets/demo/demo.css?v=1.5.0";
import "./assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit

import LandingPage from "./views/pages/LandingPage.js";
import Project from "./views/pages/Project.js"; 
import PrivacyPolicy from "./views/pages/PrivacyPolicy";
import ProjectPage from "./views/pages/Project.js";
import Partners from "./views/pages/Partners.js";
import Outcomes from "./views/pages/Outcomes";
import Galleryimg from "./views/pages/Galleryimg";
import './assets/fonts/nasalization-free.rg-regular.otf'
import Publications from "./views/pages/Publications";
import './i18n'
import Packages from "./views/pages/Packages";
import Team from "./views/pages/Team";

ReactDOM.render(
  <BrowserRouter>
  <Helmet>
        <meta charSet="utf-8"/>
        <title>ENORASI</title>
        <link rel="image_src" href="/static/media/tubers_logo2.svg" />
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        <meta name="description" content="The smooth operation of High Voltage Centers (HVC) and Substations (SS) is vital for the uninterrupted transmission and distribution of the precious resource of electrical energy.App Description" />
        <meta name="theme-color" content="#008f68" />

  </Helmet>
    <Switch>
      <Switch>
        <Route exact path="/" render={(props) => <LandingPage {...props} />} />
       

        <Route path="/gallery" component={Galleryimg} />
        <Route path="/publications" component={Publications} />
        <Route path="/packages" component={Packages} />
        <Route path="/team" component={Team} />
      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
