import React from "react";
import ead from './Image.js'
// reactstrap components
import { Button, Container } from "reactstrap";
import Fade from 'react-reveal/Fade';
import Head from './Image'
import espa from "./disclaimer.jpg"

// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  }, []);

  return (
    <>
      <div className="page-header page-header-small">
        <div id='backimg' ref={pageHeader}>
          <Head />
          <a href='http://greece20.gov.gr' target='_blank' rel="noopener noreferrer">
            <img 
              id='espa' 
              alt='espa-disclaimer' 
              src={espa} 
              style={{
                cursor: 'pointer',
                position: 'relative',
                zIndex: '100',
                willChange: 'transform',
                pointerEvents: 'auto'
              }} 
            />
          </a>
        </div>
        <div className="content-center">
          <Container></Container>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;