/*eslint-disable*/
import React from "react";
import logo from "../../assets/img/tubers_logo.svg"
// reactstrap components
import { Container } from "reactstrap";
import eu from '../../assets/img/logos/eu.jpg'
import { useTranslation } from 'react-i18next';
import '../../i18n'; // Εισαγωγή της ρύθμισης i18n


function DarkFooter() {
   const { t, i18n } = useTranslation();
  
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng); // Αλλαγή γλώσσας
      console.log(lng)
    };
  return (
    <footer className="footer">
    
   
    <div class="bg-light py-4" id='footer'>
      <div class="container text-center">
        <p style={{color:'whitesmoke',fontSize:'12px',textAlign:'justify'}}>{t('footer')}
        </p>
 <hr/>
        <p class=" mb-0 py-2" style={{color:'#dadada',fontSize:'14px'}}>© 2025 ΕΝΟΡΑΣΗ</p>
      </div>
    </div>

    </footer>
  );
}

export default DarkFooter;


/*<a id='a'href="https://www.w3schools.com" style={{color:'#dadada',fontSize:'14px',cursor:'pointer'}}>Privacy policy</a>*/